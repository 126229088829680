<template>
    <el-container class="home-page">
		<el-aside width="80px" class="navi-bar">
			<div class="user-head-image">
				<head-image :size="45" :url="avatalUrl" :name="$store.state.userStore.userInfo.nickname"></head-image>
			</div>

			<el-menu background-color="#333333" text-color="#ddd" style="margin-top: 30px;">
				<el-menu-item title="聊天">
					<router-link v-bind:to="'/home/chat/0'">
						<span class="el-icon-chat-dot-round"></span>
						<!-- <div v-show="unreadCount>0" class="unread-text">{{unreadCount}}</div> -->
					</router-link>
				</el-menu-item>
				<el-menu-item title="好友">
					<router-link v-bind:to="'/home/friend'">
						<span class="el-icon-user"></span>
					</router-link>
				</el-menu-item>
				<!-- <el-menu-item title="群聊">
					<router-link v-bind:to="'/home/group'">
						<span class="icon iconfont icon-group_fill"></span>
					</router-link>
				</el-menu-item>

				<el-menu-item title="设置" @click="showSetting()">
					<span class="el-icon-setting"></span>
				</el-menu-item> -->
			</el-menu>

			<div class="exit-box" @click="onExit()" title="退出">
				<span class="el-icon-circle-close"></span>
			</div>
		</el-aside>
		<el-main class="content-box">
			<router-view v-if="dateLoaded"></router-view>
		</el-main>
	</el-container>
</template>

<script>
import HeadImage from '../components/common/HeadImage.vue';
import {MSG_ACTION, MSG_FLAG, MSG_TYPE} from "../api/enums.js";
import {ChatMessage, DataContent, ChatHistory, ChatSnapshot} from "../api/common.js"
import { PcCookie, Key } from '@/utils/cookie.js'
import request from '@/utils/request'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Home",
    components: {
        HeadImage
    },
	data() {
		return {
			dateLoaded: false
		}
	},
	methods: {
		init() {
			this.$store.dispatch("load").then(() => {
					this.dateLoaded = true
					// this.$store.dispatch("loadFriend");
					// ws初始化
					const token = PcCookie.get(Key.tokenKey)
					this.$wsApi.connect(process.env.VUE_APP_WS_URL, token);
					this.$wsApi.onOpen(()=>{
						var chatMessage = new ChatMessage(this.$store.state.userStore.userInfo.id, null, null, null)
						var dataContent = new DataContent(MSG_ACTION.CONNECT, chatMessage, null)
						this.$wsApi.sendMessage(JSON.stringify(dataContent))
						this.fetchUnSignedChatMsg()
						this.$wsApi.heartCheck.start()
					});
					this.$wsApi.onMessage((message)=>{
						console.log("收到的消息："+message);
						var action = message.action
						if (action == MSG_ACTION.PULL_FRIEND) {
							this.$store.dispatch("loadFriend")	
							return false
						}
						//如果不是重新拉取好友列表，则获取聊天消息模型
						let chatMessage = message.chatMessage
						// let friend = this.getFriend(chatMessage.senderId)
						// let chatHistory = new ChatHistory(chatMessage.receiverId, chatMessage.senderId, chatMessage.msg, MSG_FLAG.FRIENF)
						// this.$store.commit("saveUserMessage", chatHistory)
						// let chatSnapshot = new ChatSnapshot(chatMessage.receiverId, chatMessage.senderId, chatMessage.msg, true, friend.nickname, friend.avatarUrl, new Date())
						// this.$store.commit("saveChatSnapshot", chatSnapshot)
						this.pushChatMsgToStore(chatMessage)
						this.$store.commit("saveToStorage")
						// 接收到消息后，对消息进行签收
						var dataContent = new DataContent(MSG_ACTION.SIGNED, null, chatMessage.msgId)
						this.$wsApi.sendMessage(JSON.stringify(dataContent))
					})
				}).catch((e) => {
					console.log("初始化失败", e);
				});
		},
		getFriend(userId){
			return this.$store.state.friendStore.friends.find(friend=>{
				return friend.id == userId
			})
		},
		fetchUnSignedChatMsg(){
			
			request({
                url: '/chatMsg/unSignedChat',
                method: 'GET'
            }).then((result) => {
                if (result.data.code == 20000) {
					var msgIds=",";
                    let unSignedChatMsgs = result.data.data;
                    unSignedChatMsgs.forEach(chatMsg => {
						chatMsg.createTime = new Date(chatMsg.createTime).valueOf()
						this.pushChatMsgToStore(chatMsg)
						msgIds+= chatMsg.msgId;
						msgIds+=",";
                    });
					this.$store.commit("saveToStorage")
					// 接收到消息后，对消息进行签收
					var dataContent = new DataContent(MSG_ACTION.SIGNED, null, msgIds)
					this.$wsApi.sendMessage(JSON.stringify(dataContent))
                }
            })
		},
		pushChatMsgToStore(chatMsg){
			console.log("chatMsg:"+JSON.stringify(chatMsg));
			let friend = this.getFriend(chatMsg.senderId)	
			console.log("friend:"+ JSON.stringify(friend));
			let chatHistory = new ChatHistory(chatMsg.receiverId, chatMsg.senderId, chatMsg.msg, MSG_FLAG.FRIENF, chatMsg.createTime, MSG_TYPE.TEXT)
			this.$store.commit("saveUserMessage", chatHistory)
			let chatSnapshot = new ChatSnapshot(chatMsg.receiverId, chatMsg.senderId, chatMsg.msg, true, friend.nickname, friend.avatarUrl, chatMsg.createTime)
			this.$store.commit("saveChatSnapshot", chatSnapshot)
		},
		onExit(){
			// window.opener=null; window.open('','_self'); window.close();
			const opened=window.open('about:blank','_self'); opened.close();
		}
	},
	created() {
		console.log("Home created");
		this.init();
	},
	mounted() {
		console.log("Home mounted");
	},
	computed: {
		avatalUrl(){
			if (this.$store.state.userStore.userInfo.avatarUrl != null) {
				return process.env.VUE_APP_PORTAL_URL + '/static/avatarPhoto/' +this.$store.state.userStore.userInfo.avatarUrl;	
			}else{
				return null;
			}	
		}
	}
}
</script>

<style lang="scss"> 
	.navi-bar {
		background: #333333;
		padding: 10px;
		padding-top: 50px;

		.el-menu {
			border: none;
			flex: 1;

			.el-menu-item {
				margin: 25px 0;

				.router-link-exact-active span {
					color: white !important;
				}



				span {
					font-size: 24px !important;
					color: #aaaaaa;

					&:hover {
						color: white !important;
					}
				}

				.unread-text {
					position: absolute;
					line-height: 20px;
					background-color: #f56c6c;
					left: 36px;
					top: 7px;
					color: white;
					border-radius: 30px;
					padding: 0 5px;
					font-size: 10px;
					text-align: center;
					white-space: nowrap;
					border: 1px solid #f1e5e5;
				}
			}
		}



		.exit-box {
			position: absolute;
			width: 60px;
			bottom: 40px;
			color: #aaaaaa;
			font-size: 24px;
			text-align: center;
			cursor: pointer;

			&:hover {
				color: white !important;
			}
		}
	}

	.content-box {
		padding: 0;
		background-color: #E9EEF3;
		color: #333;
		text-align: center;

	}
</style>