import request from '@/utils/request'

export default {

	state: {
		friends: [],
		activeFriend: null,
	},
    mutations: {
		setFriends(state, friends) {
			state.friends = friends;
		},
		activeFriend(state, idx) {
			state.activeFriend = state.friends[idx];
		},
		activeFriendByUserId(state, userId) {
			state.activeFriend = state.friends.find(function(friend){
					return friend.id == userId
				})
		}
    },
    actions: {
		loadFriend(context) {
			return new Promise((resolve, reject) => {
				request({
					url: '/user/friends',
					method: 'GET'
				}).then((result) => {
                    if (result.data.code == 20000) {
                        context.commit("setFriends",result.data.data);  
						console.log("loadFriend")
						resolve()
                    }else{
						reject()
					}	
				}).catch(() => {
					reject()
				})
			});
		}
	}
}