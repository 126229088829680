import {USER_STATE} from "../api/enums.js"
// import http from '../api/httpRequest.js'
import request from '@/utils/request'

export default {
	
	state: {
		userInfo: {
			
		},
		state: USER_STATE.FREE 
	},

	mutations: {
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		setUserState(state, userState) {
			state.state = userState;
		},
		clear(state){
			state.userInfo = {};
			state.state = USER_STATE.FREE;
		}
	},
	actions:{
        associateFriend(context, userId){
            return new Promise((resolve, reject) => {
				request({
					url: `/user/associateFriend/${userId}`,
					method: 'GET'
				}).then((result) => {
                    if (result.data.code == 20000) {
                        context.commit("setFriends",result.data.data);   
						resolve();
                    }else {
						reject(result.data)
					}
				}).catch((res)=>{
					reject(res);
				});
			})
        },
		loadMyInfo(context){
			return new Promise((resolve, reject) => {
				request({
					url: '/user/getMyInfo',
					method: 'GET'
				}).then((result) => {
					if (result.data.code == 20000) {
                        context.commit("setUserInfo",result.data.data);   
						resolve();
                    }else{
						reject();
					}
				}).catch((res)=>{
					reject(res);
				});
			})	
		}
		// loadUser(context){
		// 	return new Promise((resolve, reject) => {
		// 		request({
		// 			url: '/user/self',
		// 			method: 'GET'
		// 		}).then((userInfo) => {
		// 			context.commit("setUserInfo",userInfo);
		// 			resolve();
		// 		}).catch((res)=>{
		// 			reject(res);
		// 		});
		// 	})
		// }
	}
}