	/**
	 * 和后端的ChatMsg 聊天模型对象保持一致
	 * @param {Object} senderId
	 * @param {Object} receiverId
	 * @param {Object} msg
	 * @param {Object} msgId
	 */
	function ChatMessage(senderId,receiverId,msg,msgId){
		this.senderId = senderId;
		this.receiverId = receiverId;
		this.msg = msg;
		this.msgId = msgId;
	}
	/**
	 * @param {Object} myId
	 * @param {Object} friendId
	 * @param {Object} msg
	 * @param {Object} flag 判断本条消息是我发送的，还是朋友发送的  1:我  2： 朋友
	 */
	function ChatHistory(myId,friendId,msg,flag, createTime, msgType){
        this.myId = myId;
        this.friendId = friendId;
        this.msg = msg;
        this.flag = flag;
		this.createTime = createTime;
		this.msgType = msgType;
    }
	function UserMessage(friendId, lastTimeTip, chatHistorys){
		this.friendId = friendId;
		this.lastTimeTip = lastTimeTip
		this.chatHistorys = chatHistorys;
	}
	/**构建消息DataContent模型对象
	 * @param {Object} action
	 * @param {Object} chatMsg
	 * @param {Object} extand
	 */
	function DataContent(action,chatMessage,extand){
		this.action = action;
		this.chatMessage = chatMessage;
		this.extand = extand;
	}
	/**
	 * 创建快照对象的函数
	 * @param {Object} myId
	 * @param {Object} friendId
	 * @param {Object} msg
	 * @param {Object} isRead 用于判断消息是否为已读还是未读消息
	 */
    function ChatSnapshot(myId,friendId,msg,isRead, friendNickname, friendAvatar, createTime){
        this.myId = myId;
        this.friendId = friendId;
        this.msg = msg;
        this.isRead = isRead;
		this.friendNickname = friendNickname;
		this.friendAvatar = friendAvatar;
		this.createTime = createTime;
    }

    export {
        ChatMessage,
		DataContent,
		ChatHistory,
		UserMessage,
        ChatSnapshot
    }