import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  PcCookie,
  Key
} from '@/utils/cookie.js'
// import Chat from '../views/Chat.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   component: Chat
  // },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [{
        name: "Chat",
        path: "/home/chat/:userId",
        component: () => import("../views/Chat"),
      },
      {
        name: "Friends",
        path: "/home/friend",
        component: () => import("../views/Friend"),
      },
      // {
      //   name: "Friends",
      //   path: "/home/friend",
      //   component: () => import("../view/Friend"),
      // },
      // {
      //   name: "Friends",
      //   path: "/home/group",
      //   component: () => import("../view/Group"),
      // }
    ]
  }

]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  // if (PcCookie.get(Key.accessTokenKey) && PcCookie.get(Key.userInfoKey)) {
  if (PcCookie.get(Key.tokenKey)) {
    next()
  } else {
    window.location.href = `${process.env.VUE_APP_AUTH_CENTER_URL}/login?redirectURL=${window.location.href}`
  }
});

export default router