import {UserMessage} from "../api/common.js"
import userStore from './userStore';
import {MSG_TYPE} from "@/api/enums.js";

export default {

	state: {
        chatSnapshotList: [],
        userMessages: []
	},
    mutations: {
        initChats(state, chatsData) {
			state.chatSnapshotList = chatsData.chatSnapshotList || [];
            state.userMessages = chatsData.userMessages || [];
		},
        saveChatSnapshot(state, chatSnapshot) {
            for (let i = 0; i < state.chatSnapshotList.length; i++) {
                if (state.chatSnapshotList[i].friendId == chatSnapshot.friendId) {
                    //删除已经存在的friendId 所对应的快照对象
                    let splicedSnapshot = state.chatSnapshotList[i]
					state.chatSnapshotList.splice(i,1);
                    if ((chatSnapshot.msg == null && splicedSnapshot.msg != null) || (splicedSnapshot.createTime > chatSnapshot.createTime)) {
                        chatSnapshot = splicedSnapshot
                    }
					break;
                }
            }
            state.chatSnapshotList.unshift(chatSnapshot);
            // this.commit("saveToStorage");
        },
        saveUserMessage(state, chatHistory) {
            var noHaveUserMessage = true
            for (let i = 0; i < state.userMessages.length; i++) {
                if (state.userMessages[i].friendId == chatHistory.friendId) {
                    if (!state.userMessages[i].lastTimeTip || (state.userMessages[i].lastTimeTip < chatHistory.createTime - 600 * 1000)){
                        state.userMessages[i].chatHistorys.push({
                            createTime: chatHistory.createTime,
					        msgType: MSG_TYPE.TIP_TIME,
                        })
                        state.userMessages[i].lastTimeTip = chatHistory.createTime;  
                        state.userMessages[i].chatHistorys.forEach(chat => {
                            if (chat.msgType == MSG_TYPE.TIP_TIME) {
                                chat.createTime = chat.createTime - 1   
                            }
                        })
                    }
                    state.userMessages[i].chatHistorys.push(chatHistory)
                    state.userMessages[i].chatHistorys.sort((a, b) => a.createTime - b.createTime)
                    noHaveUserMessage = false
                    break  
                }
                
            }
            if (noHaveUserMessage) {
                let userMessage = new UserMessage(chatHistory.friendId, chatHistory.createTime, [chatHistory])
                state.userMessages.push(userMessage);    
            }
            // this.commit("saveToStorage");
        },
        saveToStorage(state) {
			let userId = userStore.state.userInfo.id;
			let key = "chats-" + userId;
			let chatsData = {
				chatSnapshotList: state.chatSnapshotList,
				userMessages: state.userMessages
			}
			localStorage.setItem(key, JSON.stringify(chatsData));
		},
    },
    actions: {
        loadChat(context) {
			return new Promise((resolve) => {
				let userId = userStore.state.userInfo.id;
				let key = "chats-" + userId;
				let item = localStorage.getItem(key)
				if (item) {
					let chatsData = JSON.parse(item);
					context.commit("initChats", chatsData);
				}
				resolve();
			})
		},
    }

}