const USER_STATE = {
	OFFLINE: 0,
	FREE: 1,
	BUSY: 2
}

const MSG_ACTION = {
	CONNECT: 1, 		//第一次(或重连)初始化连接
	CHAT: 2,    		//聊天消息
	SIGNED: 3,  		//消息签收
	KEEPALIVE: 4, 		//客户端保持心跳
	PULL_FRIEND: 5, 	//拉取好友
}

const MSG_FLAG = {
	MY: 1,		//消息是我发出的
	FRIENF: 2	//消息是朋友发出的
}

const MSG_TYPE = {
	TEXT: 0,
	TIP_TIME:10,
}

export {
	USER_STATE,
	MSG_ACTION,
	MSG_FLAG,
	MSG_TYPE
}
