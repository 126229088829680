import axios from 'axios'
import { PcCookie, Key } from '@/utils/cookie.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: process.env.VUE_APP_SERVICE_URL,
  timeout: 10000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    const tokenKey = PcCookie.get(Key.tokenKey)
    if(tokenKey) {
      // 针对每个请求，请求头带上令牌 Authorization: Bearer token
      config.headers.Authorization = 'Bearer ' + tokenKey
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => { 
    if(response.data.code === 1400 || response.data.code === 1401 || response.data.code === 1402) {
      // 移除cookie中的数据
      PcCookie.remove(Key.tokenKey)
      PcCookie.remove(Key.nicknameKey)
      window.location.href = `${process.env.VUE_APP_AUTH_CENTER_URL}/login?redirectURL=${window.location.href}`  
    }
    return response
  },
  error => {
    // 响应异常
    const { status } = error.response
    if (status === 401) {
      window.location.href = `${process.env.VUE_APP_AUTH_CENTER_URL}/login?redirectURL=${window.location.href}`  
    }
    return Promise.reject(error)
  }
)

export default service