import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './userStore.js';
import friendStore from './friendStore.js';
import chatStore from './chatStore.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    load(context) {
			console.log("load")
			return this.dispatch("loadMyInfo").then(() => {
				const promises = [];
				promises.push(this.dispatch("loadFriend"));
				promises.push(this.dispatch("loadChat"));
				return Promise.all(promises);
			})
		},
  },
  modules: {
    userStore,
    friendStore,
    chatStore
  }
})
