import './assets/styles/global.css'
import './assets/styles/iconfont.css'
import Vue from 'vue'
import { Button,  Row, Col, Card , Container, Aside, Input, Scrollbar, Dialog,
  Header, Main, Footer, Menu, MenuItem, Descriptions, DescriptionsItem, Divider} from 'element-ui';
import App from './App.vue'
import router from './router'
import store from './store'
import * as socketApi from './api/wssocket';
import emotion from './api/emotion.js';
import element from './api/element.js';
import * as  date from './api/date.js';
import * as  enums from './api/enums.js';

Vue.config.productionTip = false

Vue.component(Button.name, Button);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Card.name, Card);
Vue.component(Container.name, Container);
Vue.component(Aside.name, Aside);
Vue.component(Input.name, Input);
Vue.component(Scrollbar.name, Scrollbar);
Vue.component(Header.name, Header);
Vue.component(Main.name, Main);
Vue.component(Footer.name, Footer);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Descriptions.name, Descriptions);
Vue.component(DescriptionsItem.name, DescriptionsItem);
Vue.component(Divider.name, Divider);
Vue.component(Dialog.name, Dialog);

Vue.prototype.$wsApi = socketApi;
Vue.prototype.$emo = emotion; // emo表情
Vue.prototype.$elm = element; // 元素操作
Vue.prototype.$date = date;
Vue.prototype.$enums = enums; // 枚举

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
